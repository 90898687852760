<template>
	<div>
		<v-card>
			<s-toolbar label="Resumen KG" dark color="#8e8f91"></s-toolbar>
			<v-container>

                <v-row>
                    
                    <v-col cols="3"><v-switch v-model="ViewZonLocation" @click="load()">Por Zona</v-switch></v-col>
                    <v-col cols="3"><s-date v-model="filter.cDateInitial" label="Fecha Inicio" :disabled="filter.TrnID > 0 ? true: false"></s-date></v-col>
					<v-col cols="3"><s-date v-model="filter.cDateFin" label="Fecha Fin" :disabled="filter.TrnID > 0 ? true: false"></s-date></v-col>
					<v-col cols="3"><s-turn-in-process :TypeArea="'1'" clearable v-model="filter.TrnID"></s-turn-in-process></v-col>
                </v-row>
				<v-row>
					<!-- <v-col :cols="ViewTotal==true ? 4 : 12"><v-switch v-model="ViewTotal" @click="load()" label="Por Lote Prod."></v-switch></v-col>
					<v-col v-if="ViewTotal" cols="8"><s-text label="N° Lote de Producción" v-model="RcfID" @input="inputLote($event)" ></s-text></v-col>
					 -->
                   

					<v-col cols="12" lg="12">
						<v-row>
							<h3 class="mt-3 ml-6">KG RECEPCION FRESCOS</h3>
							<v-spacer> </v-spacer>
							
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="12"
								lg="6"
                                md="6"
								v-for="item in Object.keys(itemsKg)"
							>
								<v-row>
									<h3 class="mt-3 ml-6">{{ item }}</h3>
									<v-spacer> </v-spacer>
								</v-row>

								<v-row>
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-col lg="12" class="pt-0">
									<v-list
										v-for="d in itemsKg[item]"
									>
										<v-list-item>
											<v-list-item-avatar>
												<v-btn
													small
													elevation="0"
													fab
													dark
													color="info"
												>
													<b
														><h2>
															{{
																d.WpbQuantityParihuela
															}}
														</h2></b
													>
												</v-btn>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title>
													<h1>
														{{
															d.WpbWeightNet
														}} <b style="font-family: Courier; font-size: 18px;"> KG</b>
														
													</h1>
													<b style="color: blue"></b>
												</v-list-item-title>

												<v-list-item-subtitle v-if="ViewZonLocation">
													<b><h2>{{ d.TypeZonLocationName }}</h2></b>
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: thin">{{d.TypeCropName}} - {{d.VrtName}}</b><br>
                                                    <b style="text-transform: uppercase;">{{d.WpbJabasQuantity}} - {{d.WpbWeightGross}} PB</b>
													
												</v-list-item-subtitle>
												<v-list-item-subtitle v-if="!ViewZonLocation">
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: thin">{{d.TypeCropName}} - {{d.VrtName}}</b><br>
                                                    <b style="text-transform: uppercase;">{{d.WpbJabasQuantity}} - {{d.WpbWeightGross}} PB</b>
												</v-list-item-subtitle>
											</v-list-item-content>
											<!-- <v-btn
										x-small
										elevation="0"
										fab
										dark
										color="error"
									>
										<v-icon style="font-size: 20px !important">
											fas fa-times
										</v-icon>
									</v-btn> -->
										</v-list-item>
									</v-list>
								</v-col>
								
							</v-col>

							<v-col cols="12" >
									<b>Total KG: </b>
									<v-chip color="error" x-large>{{sumTotalKG.toFixed(2)}}</v-chip>
							</v-col>

							<v-divider vertical></v-divider>
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-btn color="info" rounded @click="openModalGraph = true">Ver Grafico de Barras</v-btn>
								<!-- <CardLineChart ref="chart" :objLineX="objLineX" :objLineY="objLineY"></CardLineChart> -->
							</v-col>

							<v-dialog
								v-if="openModalGraph"
								v-model="openModalGraph"
							>
								<v-card>
									<v-row style="margin:auto">
										<h3 class="mt-3 ml-6"></h3>
										<v-spacer> </v-spacer>
										<v-btn
										@click="openModalGraph=false"
										class="mt-3 mr-3"
										small
										fab
										color="error"
										><i style="font-size: 16px" class="fas fa-times"></i
										></v-btn>
									</v-row>

									<v-row  style="margin:auto">
										<v-col class="pb-0">
										<v-divider></v-divider>
										</v-col>
									</v-row>

									<v-row  style="margin:auto">
										
										<v-col cols="12" lg="6" md="6" class="pt-0">
										<CardBarChart :text="'KG'" :objLineX="objLineX" :objLineY="objLineY" :objLineY2="null" ></CardBarChart>
										</v-col>

										<v-col cols="12" lg="6" md="6" class="pt-0">
										<CardLineChart :text="'KG'" :objLineX="objLineX" :objLineY="objLineY"></CardLineChart>
										</v-col>

									</v-row>
								</v-card>
							</v-dialog>
						</v-row>
					</v-col>

				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>

	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService.js";
	import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue"
	import CardBarChart from '@/components/Utils/Cards/CardBarChart' ;
	import CardLineChart from '@/components/Utils/Cards/CardLineChart' ;


	export default {
		components:{sTurnInProcess, CardBarChart, CardLineChart},
		data() {
			return {
				filter: {},
				itemsKg: [],
                ViewZonLocation: true,
				sumTotalKG: 0,
				objLineX: [],
				objLineY: [],
				openModalGraph:false
			};
		},
		watch: {
			
			'filter.cDateInitial'(){
				this.load()
			},
			'filter.cDateFin'(){
				this.load()
			},
			'filter.TrnID'(){

				if(this.filter.TrnID > 0){
					this.filter.cDateInitial = null
					this.filter.cDateFin = null
					
				}

				this.load()
				
			},

		},
		methods: {
			load() {
				
				_sReceptionFresh
					.GetKgReception({TrnID: this.filter.TrnID, ViewZonLocation: this.ViewZonLocation , cDateInitial: this.filter.cDateInitial, cDateFin: this.filter.cDateFin}, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {

							let items = resp.data;
							
							this.objLineX = []
							this.objLineY = []
							items.forEach(element => {
								if(this.ViewZonLocation){
									this.objLineX.push(element.TypeZonLocationName)
									this.objLineY.push(element.WpbWeightNet)

									
									/* console.log("cajaaa",element); */
								}else{
									this.objLineX = []
									this.objLineY= []
								/* }else{
									this.objLineX.push(element.TypeCropName)
									this.objLineY.push(element.WpbWeightNet)
									console.log("cajaaa", element); */
								}
								
							});

							this.itemsKg = _.groupBy(
								items,
								"TypeProcessingPlantName"
							);

							this.isSum()

                           /*  console.log(this.itemsKg); */

							

						}
					});

					
			},

			isSum(){
				var sum  = 0
					for (let prop in this.itemsKg) {
						this.itemsKg[prop].map((i) => {
							sum += parseFloat(i.WpbWeightNet)
						});
					}
					this.sumTotalKG = sum
			}

		},
		created() {
			this.load();
			
		},

	};
</script>

<style>
</style>